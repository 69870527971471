<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <lms-default-loader v-if="isLoading" />

    <f-container topBottom v-if="!isLoading">
      <f-form />
    </f-container>
  </div>
</template>

<script lang="js">
import FForm from '../../../components/views/settings/configs/BetweenExamsForm.vue';

export default {
  components: {
    FForm
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t("labels.settings"),
          route: {
            name: "settings"
          }
        }
      ];
    },
    title() {
      return this.$t("settings.names.time_wait_exam_tries");
    },
  },
  created() {
    this.get();
  },
  methods: {
		async get(){
			await this.$store.dispatch('setting/getData', 'time_wait_exam_tries')
		},
  },
  beforeDestroy(){
    this.$store.dispatch('setting/clear', ['dataValues'])
  }
};
</script>
