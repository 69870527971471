<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-text-field
          :label="$t('labels.hours')"
          outlined
          hide-details
          v-model="dataValues.time_wait_exam_tries"
        />
      </v-card-text>
    </v-card>
    <lms-btn-holder>
      <v-btn large depressed color="primary" @click="save">
        {{ $t('labels.save') }}
      </v-btn>
      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </div>
</template>

<script lang="js">
export default {
	computed: {
		dataValues(){
			return this.$store.state.setting.dataValues;
		},
  },
  methods: {
    async save() {
			this.$store.dispatch(
				'setting/saveData',
				'time_wait_exam_tries'
			);
			this.back();
    },
    async back() {
			this.$router.push({name:'settings'});
    }
  }
}
</script>
